export const getBaseURL = ()=>{
    let API_URL = '';
    if (process.env.NODE_ENV === "development") {
        API_URL = process.env.REACT_APP_API_URL_LOCAL;
    } else if (process.env.NODE_ENV === "production") {
        API_URL = process.env.REACT_APP_API_URL_PRODUCTION;
    }
    return API_URL
}

export const BASE_URL = getBaseURL();

export const ERROR_CODE = [
    {
        code: 0,
        message: 'Page Loading...'
    },
    {
        code: 404,
        message: 'Resource Not Found.'
    },
    {
        code: 401,
        message: 'Unauthorized.'
    },
    {
        code: 403,
        message: 'Forbidden.'
    },
    {
        code: 400,
        message: 'Bad Request.'
    },
    {
        code: 500,
        message: 'Internal Server Error.'
    },
    {
        code: 502,
        message: 'Bad Gateway.'
    },
    {
        code: 503,
        message: 'Service Unavailable.'
    },
    {
        code: 504,
        message: 'Gateway Timed Out.'
    },
    {
        code: 'default',
        message: 'Unexpected Error.'
    },
]