import './PaymentForm.css';

const NoMatch = () => {
    return ( 
        <div className="paymentForm">
            <p className='header'>Un Authorized</p>
            <p className='subheader'>No access to this page.</p>
        </div>
     );
}
 
export default NoMatch;