import { useEffect, useState } from "react";
import api from '../api/ApiAgent';

import './PaymentForm.css';

import {
    useParams
  } from "react-router-dom";
import TP_Button from './Button';
import { ERROR_CODE } from '../constants';

const PaymentForm = (props) => {
    const { extraCharge } = props;
    const [paymentValue, setPaymentValue] = useState(0);
    const [payment_link, setPayment_Link] = useState('')
    const [amountReceiveIn, setAmountReceiveIn] = useState('');
    const [baseInputCost, setBaseInputCost] = useState(0);
    const [paymentList, setPaymentList] = useState([]);
    const [urlID, setUrlID] = useState('');
    const { id } = useParams() || '';
    const [status_code, setStatus_Code] = useState(0);
    const [success, setSuccess] = useState(false);
    const [errorHeader, setErrorHeader] = useState('')
    const [errorSubHeader, setErrorSubHeader] = useState(ERROR_CODE.find((error)=> error.code === 0).message)
    const [disableSubmitButton, setDisableSubmitButton] = useState(true)

    useEffect(()=>{
        
        setUrlID(id);
        const getPaymentList = async () => {
            try {
                let response = await api.get(`payment/${id}/detail/`);
                // console.log('api response:', response.data)
                let status_code = response?.data?.status_code || 404;
                let success = response?.data?.success || false;
                setStatus_Code(status_code);
                setSuccess(success)
                setAmountReceiveIn(response?.data?.payload?.amount_receive_in);
                setBaseInputCost(response?.data?.payload?.base_input_cost);
                let tip_amounts = response?.data?.payload?.tip_amounts || [];
                let paymentArr = []
                tip_amounts.forEach(tipAmount => {
                    let objTip = {amount: tipAmount.amount, customer_markup_amount: tipAmount.customer_markup_amount, payment_link: tipAmount.payment_link, customer_pay_payment_link:tipAmount.customer_pay_payment_link, percentage: tipAmount.percentage}
                    paymentArr.push(objTip)
                });
                setPaymentList(paymentArr);
                
                
                
            } catch (error) {
                console.log('error:', error)
                let status_code = error.response.status
                setStatus_Code(status_code);
                setSuccess(false)

                let errorObj = ERROR_CODE.find((error)=> error.code === status_code) || 
                    ERROR_CODE.find((error)=> error.code === 'default');
                    
                setErrorHeader(status_code)
                setErrorSubHeader(errorObj.message)
            }
            
        }
        getPaymentList();
    }, [])
    const onChange = (event, i)=>{
        // console.log('selected option', event.target.value);
        // console.log('selected option index', i);
        assignPaymentValue(extraCharge ? paymentList[i].customer_markup_amount + paymentList[i].amount : paymentList[i].amount, i)
    }

    const onClickLi = (index)=>{
        assignPaymentValue(extraCharge ? paymentList[index].customer_markup_amount + paymentList[index].amount : paymentList[index].amount, index)
    }

    const assignPaymentValue = (value, index)=>{
        setPaymentValue(value)
        setPayment_Link(extraCharge ? paymentList[index].customer_pay_payment_link : paymentList[index].payment_link)
        setDisableSubmitButton(false)
    }

    const handleSubmit = ()=>{
        // console.log('id: ', urlID);
        // console.log('paymentValue: ', paymentValue)
        // console.log('payment_link: ', payment_link)
        window.location.href = payment_link;
    }

    const getPaymentLabel = (payment) => {
        if(amountReceiveIn === 'P'){
           return extraCharge ? '$' + `${payment.amount} (${payment.percentage}% tip of base service charge $${baseInputCost})` + ' + ' + '$' + `${payment.customer_markup_amount}` : 
           '$' + `${payment.amount} (${payment.percentage}% tip of base service charge $${baseInputCost})`;
        }
        if(amountReceiveIn !== 'P') {
            return extraCharge ? '$' + `${payment.amount}` + ' + ' + '$' + `${payment.customer_markup_amount}` : '$' + `${payment.amount}`;
        }
    }
    
    return ( 
        <div className="paymentForm">
            {!success && status_code !== 200?(
                <>
                    <p className='header'>{errorHeader}</p>
                    <p className='subheader'>{errorSubHeader}</p>
                    <p className='header'>&nbsp;</p>
                </>
            ):(
                <>
                    <p className='header'>Tip Now!</p>
                    <p className='subheader'>Your Generosity is Apprecited More Than You Know</p>
                    <div id="listArea">
                        <ul className='ul-listArea'>
                            {
                            paymentList.map((payment, index) => {
                                return (<li onClick={()=>onClickLi(index)} key={`li_${index}`}>
                                <input type="radio" id={`radio_${index}`} name="tipAmount" value={extraCharge ? payment.customer_markup_amount + payment.amount : payment.amount} onChange={(ev)=>onChange(ev, index)} checked={extraCharge ? paymentValue===payment.amount + payment.customer_markup_amount : paymentValue===payment.amount}/>
                                <label htmlFor={`radio_${index}`}>{getPaymentLabel(payment)}
                                    {/* {extraCharge ? '$' + `${payment.amount}` + ' + ' + '$' + `${payment.customer_markup_amount}` : '$' + `${payment.amount}` }{amountReceiveIn === 'P' ? ` (${payment.percentage}% tip of base service charge ${baseInputCost})` : ''} */}
                                <em>{extraCharge ? ' processing fees' : ''}</em></label>
                                </li>)
                            })
                            }  
                        </ul>
                    </div>
                    <TP_Button 
                        backgroundColor="#FFAF00" 
                        borderColor="#FFAF00"
                        color="#FFFFFF"
                        handleButtonClick={handleSubmit}
                        ButtonText="Proceed"
                        disabled={disableSubmitButton}
                    />
                </>
            )}
        </div>
    );
}
 
export default PaymentForm;