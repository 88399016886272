import styled from 'styled-components';
const Button = styled.button`
    box-sizing: border-box;

    width: 80%;
    height: 48px;
    
    background: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor};
    border-radius: 100px;
    text-align: center;
    margin: auto;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: ${props => props.color};
    margin-bottom: 51px;
    `;
const TP_Button = (props) => {
    const {backgroundColor, borderColor, color, handleButtonClick, ButtonText, disabled} = props;
    
    return ( 
        <Button 
        backgroundColor={backgroundColor}
        color={color}
        borderColor={borderColor}
        onClick={handleButtonClick}
        disabled={(disabled)?true:false}
        >
            {ButtonText}
        </Button>
     );
}
 
export default TP_Button;