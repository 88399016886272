import { useEffect, useState } from "react";
import './ConfirmPayment.css';
import {
    useParams,
    useNavigate,
  } from "react-router-dom";
import TP_Button from './Button';

const ConfirmPayment = (props) => {
    // const [confirm, setConfirm] = useState(false);
    const { id } = useParams() || '';
    let navigate = useNavigate()
    
   

    const handleSubmit = (proceed)=>{
        if(proceed){
            navigate(`/pay/${id}/extracharge`)
        }else{
            navigate(`/pay/${id}`)
        }
    }
    
    return ( 
        <div className="confirmPaymentForm">
            <p className='header'>Are you okay with paying the extra charges?</p>
            <p className='subheader'>Our team works hard to ensure you have a great experience, and any additional support is greatly appreciated.</p>
            <TP_Button 
                backgroundColor="#FFAF00" 
                borderColor="#FFAF00"
                color="#FFFFFF"
                handleButtonClick={()=>handleSubmit(true)}
                ButtonText="Yes"
            />
            <TP_Button 
                backgroundColor="#FFFFFF" 
                borderColor="#000064"
                color="#000064"
                handleButtonClick={()=>handleSubmit(false)}
                ButtonText="No Thanks"
            />
        </div>
    );
}
 
export default ConfirmPayment;