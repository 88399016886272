import './App.css';
import PaymentForm from "./components/PaymentForm.js";
import NoMatch from './components/NoMatch';
import ConfirmPayment from "./components/ConfirmPayment";

import {
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <div className="App">
    <Routes>
      <Route path="/pay/:id" element={<PaymentForm extraCharge={false}/>} />
      <Route path="/pay/:id/extracharge" element={<PaymentForm extraCharge={true}/>} />
      <Route path="/confirmpayment/:id" element={<ConfirmPayment />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
    </div>
  );
}

export default App;
